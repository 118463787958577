import * as yup from 'yup'

export enum Permissions {
  ACCESS_BACKOFFICE = 'access-backoffice',

  CREATE_PRACTICE = 'create-practice',
  READ_PRACTICE = 'read-practice',
  UPDATE_PRACTICE = 'update-practice',
  DELETE_PRACTICE = 'delete-practice',

  CREATE_DOCUMENT = 'create-document',
  READ_DOCUMENT = 'read-document',
  UPDATE_DOCUMENT = 'update-document',
  DELETE_DOCUMENT = 'delete-document',

  CREATE_METADATA = 'create-metadata',
  READ_METADATA = 'read-metadata',
  UPDATE_METADATA = 'update-metadata',

  ADD_COMMENT = 'add-comment',
  VIEW_COMMENT = 'view-comment',

  // PreCheck permissions
  START_PRE_CHECK = 'start-pre-check',

  //Approval permissions
  START_APPROVAL = 'start-approval',
  FORCE_FINISH_APPROVAL = 'force-finish-approval',
  ABORT_APPROVAL = 'abort-approval',
  DEFINE_APPROVAL = 'define-approval',
  APPROVE_APPROVAL = 'approve-approval',
  REJECT_APPROVAL = 'reject-approval',
  READ_APPROVAL_STEPS = 'read-approval-steps',

  REQUEST_FEEDBACK = 'request-feedback',
  CREATE_FEEDBACK = 'create-feedback',

  ADD_COLLABORATOR = 'add-collaborator',

  CREATE_ANNOUNCEMENT = 'create-announcement',
  READ_ANNOUNCEMENT = 'read-announcement',
  UPDATE_ANNOUNCEMENT = 'update-announcement',
  // No use rn
  MANAGE_TRANSLATIONS = 'manage-translations',
  READ_TRANSLATIONS = 'read-translations',

  MANAGE_TEMPLATES = 'manage-templates',

  EDIT_FILE_WOPI = 'edit-file-wopi',
  VIEW_FILE_WOPI = 'view-file-wopi',

  VIEW_DASHBOARD = 'view-dashboard',

  ASSIGN_ROLES = 'assign-roles',
}

export enum SearchQueryParams {
  QUERY = 'search',
  LIMIT = 'limit',

  AUTHOR = 'au',
  CERTIFICATION = 'ce',
  COLLECTION = 'cl',
  COUNTRY = 'co',
  DEPARTMENT = 'de',
  LANGUAGE = 'la',
  LEGAL_ENTITY = 'le',
  LOCATION = 'lo',
  PRACTICE_CLASS = 'pc',
  PUBLISHED_DATE_FROM = 'df',
  PUBLISHED_DATE_TO = 'dt',
  RELEVANCE = 'rv', // show job related content
  SHOW_RELEVANT = 'fr', // secondary consent for show job related content
  TIME_FILTER = 'ti',
  TOPIC = 'to',
  TYPE = 'ty',
}

export enum MimeTypes {
  '.aac' = 'audio/aac',
  '.abw' = 'application/x-abiword',
  '.arc' = 'application/x-freearc',
  '.avi' = 'video/x-msvideo',
  '.azw' = 'application/vnd.amazon.ebook',
  '.bin' = 'application/octet-stream',
  '.bmp' = 'image/bmp',
  '.bz' = 'application/x-bzip',
  '.bz2' = 'application/x-bzip2',
  '.csh' = 'application/x-csh',
  '.css' = 'text/css',
  '.csv' = 'text/csv',
  '.doc' = 'application/msword',
  '.docx' = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.eot' = 'application/vnd.ms-fontobject',
  '.epub' = 'application/epub+zip',
  '.gz' = 'application/gzip',
  '.gif' = 'image/gif',
  '.htm' = 'text/html',
  '.html' = 'text/html',
  '.ico' = 'image/vnd.microsoft.icon',
  '.ics' = 'text/calendar',
  '.jar' = 'application/java-archive',
  '.jpeg' = '.jpg',
  '.js' = 'text/javascript',
  '.json' = 'application/json',
  '.jsonld' = 'application/ld+json',
  '.mid' = '.midi',
  '.mjs' = 'text/javascript',
  '.mp3' = 'audio/mpeg',
  '.mpeg' = 'video/mpeg',
  '.mpkg' = 'application/vnd.apple.installer+xml',
  '.odp' = 'application/vnd.oasis.opendocument.presentation',
  '.ods' = 'application/vnd.oasis.opendocument.spreadsheet',
  '.odt' = 'application/vnd.oasis.opendocument.text',
  '.oga' = 'audio/ogg',
  '.ogv' = 'video/ogg',
  '.ogx' = 'application/ogg',
  '.opus' = 'audio/opus',
  '.otf' = 'font/otf',
  '.png' = 'image/png',
  '.pdf' = 'application/pdf',
  '.php' = 'application/php',
  '.ppt' = 'application/vnd.ms-powerpoint',
  '.pptx' = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.rar' = 'application/vnd.rar',
  '.rtf' = 'application/rtf',
  '.sh' = 'application/x-sh',
  '.svg' = 'image/svg+xml',
  '.swf' = 'application/x-shockwave-flash',
  '.tar' = 'application/x-tar',
  '.tif' = 'image/tiff',
  '.tiff' = 'image/tiff',
  '.ts' = 'video/mp2t',
  '.ttf' = 'font/ttf',
  '.txt' = 'text/plain',
  '.vsd' = 'application/vnd.visio',
  '.wav' = 'audio/wav',
  '.weba' = 'audio/webm',
  '.webm' = 'video/webm',
  '.webp' = 'image/webp',
  '.woff' = 'font/woff',
  '.woff2' = 'font/woff2',
  '.xhtml' = 'application/xhtml+xml',
  '.xls' = 'application/vnd.ms-excel',
  '.xlsx' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.xml' = 'XML',
  '.xul' = 'application/vnd.mozilla.xul+xml',
  '.zip' = 'application/zip',
  '.3gp' = 'video/3gpp',
  '.3g2' = 'video/3gpp2',
  '.7z' = 'application/x-7z-compressed',
}

export const selectOptionYupShape = {
  id: yup.string().required(),
  label: yup.string().required(),
  value: yup.mixed().required(),
}

export enum OfficeName {
  FRONT = 'front-office',
  BACK = 'back-office',
}

export enum PracticeQueryParams {
  METADATA_LANGUAGE = 'ml',
  REVISION_STATUS = 'rs',
}

export * from '../enums/revisionStatus'
export * from './accessRights'
