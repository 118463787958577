import { CommentWithReplies } from 'types'

import Comment from './Comment'
import styles from './Comments.module.scss'

interface CommentsListProps {
  comments: CommentWithReplies[]
}

const Comments = ({ comments }: CommentsListProps): JSX.Element => {
  return (
    <div className={styles.root}>
      {comments.map((comment) => {
        const { replies, ...rest } = comment

        return (
          <>
            <div className={styles.comment}>
              <Comment key={comment.id} comment={rest} />
            </div>
            {replies.length > 0 &&
              replies.map((reply) => (
                <div className={styles.childComment}>
                  <Comment key={reply.id} comment={reply} />
                </div>
              ))}
          </>
        )
      })}
    </div>
  )
}

export default Comments
