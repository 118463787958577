import { createApi } from '@reduxjs/toolkit/query/react'
import { buildQueryString } from 'helpers'
import {
  Approval,
  CursorPaginateData,
  Highlight,
  MetadataRequest,
  MetadataResponse,
  PaginatedData,
  Practice,
  PreCheckStatus,
  Revision,
} from 'redux-store/models'
import { ListResponse, SearchableListArgs } from 'redux-store/models/misc'
import { baseQueryWithReauth } from 'redux-store/services/services.utils'
import {
  ApiSuccessResponse,
  CommentWithReplies,
  PracticeClass,
  User,
} from 'types'

import {
  AddCommentToRevisionBody,
  AddPreCheckComment,
  ApprovalPreStart,
  CreatePracticeRequest,
  CreatePracticeResponse,
  FeedbackerSearchParams,
  GetCollaboratorsApiResponse,
  GetPracticeByIdResponse,
  StartPreCheckParams,
  UpdatePracticeRequest,
} from './practicesApi.types'

export const practicesApi = createApi({
  reducerPath: 'practicesApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllPractices: builder.query<
      PaginatedData<Practice>,
      null | { isBackOffice: boolean; page?: number; limit?: number }
    >({
      query: (config) =>
        `practices/${
          !!config && config.isBackOffice ? 'backoffice' : 'frontoffice'
        }${buildQueryString({ page: config?.page, limit: config?.limit })}`,
    }),
    getRecommendedPractices: builder.query<
      PaginatedData<Practice>,
      null | { limit: number }
    >({
      query: (config) =>
        `practices/frontoffice?order_by=updated_at&order_dir=desc&limit=${
          (!!config && config.limit) ?? 15
        }`,
    }),
    getExistingPractices: builder.query<
      ListResponse,
      SearchableListArgs | null
    >({
      query: (args) => `guidelines/list${buildQueryString(args)}`,
    }),
    getPracticeById: builder.query<
      ApiSuccessResponse<GetPracticeByIdResponse>,
      string
    >({
      query: (id) => `practices/${id}`,
    }),
    getMinimalPracticeById: builder.query<
      ApiSuccessResponse<GetPracticeByIdResponse>,
      string
    >({
      query: (id) => `practices/${id}/view`,
    }),
    getLatestRevisionByPractice: builder.query<Revision, string>({
      query: (id) => `practices/${id}/revisions/latest`,
    }),
    getRevisionById: builder.query<ApiSuccessResponse<Revision>, string>({
      query: (revisionId) => `revisions/${revisionId}`,
    }),
    searchPracticeFeedbackers: builder.query<
      PaginatedData<User>,
      FeedbackerSearchParams
    >({
      query: ({ search, limit }) =>
        `users/feedbackers?search=${search}&limit=${limit ?? 20}`,
    }),
    getPreCheckStatusByPracticeId: builder.query<
      ApiSuccessResponse<PreCheckStatus>,
      string
    >({
      query: (id) => `practices/${id}/feedback`,
    }),
    getLatestApprovalByPracticeId: builder.query<
      ApiSuccessResponse<Approval | []>,
      string
    >({
      query: (id) => `practices/${id}/approval/latest`,
    }),
    getCommentsByPracticeId: builder.query<
      CursorPaginateData<CommentWithReplies>,
      { practiceId: string; limit?: number }
    >({
      query: ({ practiceId, limit }) =>
        `practices/${practiceId}/comments?limit=${limit ?? 5}`,
    }),
    getCollaboratorsByPracticeId: builder.query<
      GetCollaboratorsApiResponse,
      string
    >({
      query: (practiceId) => `practices/${practiceId}/collaborators`,
    }),
    getPracticeClasses: builder.query<CursorPaginateData<PracticeClass>, null>({
      query: () => 'practices/classes',
    }),
    getPracticeMetadata: builder.query<
      ApiSuccessResponse<MetadataResponse>,
      string
    >({
      query: (practiceId) => `practices/${practiceId}/upload/metadata`,
    }),
    getHighlights: builder.query<ApiSuccessResponse<Highlight[]>, null>({
      query: () => 'highlights',
    }),

    /*
    -----------------
    👇 MUTATIONS 👇
    -----------------
    */
    createPractice: builder.mutation<
      CreatePracticeResponse,
      CreatePracticeRequest
    >({
      query: (values) => {
        return {
          url: 'practices',
          method: 'POST',
          body: values,
        }
      },
    }),
    updateMetadataByPractice: builder.mutation<
      MetadataResponse,
      UpdatePracticeRequest
    >({
      query: ({ practiceId, data }) => ({
        url: `practices/${practiceId}`,
        method: 'PUT',
        body: data,
      }),
    }),
    startPreCheckByPracticeId: builder.mutation<any, StartPreCheckParams>({
      query: ({ id, users, dueDate, title, comment }) => ({
        url: `practices/${id}/feedbackers`,
        method: 'POST',
        body: {
          user_ids: users,
          due_date: dueDate,
          title,
          comment,
        },
      }),
    }),
    addComment: builder.mutation<any, AddPreCheckComment>({
      query: ({ id, comment }) => ({
        url: `practices/${id}/comments`,
        method: 'POST',
        body: {
          comment,
        },
      }),
    }),
    addCommentToRevision: builder.mutation<any, AddCommentToRevisionBody>({
      query: ({ id, comment, parentCommentId }) => ({
        url: `practices/${id}/comments`,
        method: 'POST',
        body: {
          comment,
          parent_id: parentCommentId || null,
        },
      }),
    }),
    abortCheckByPracticeId: builder.mutation<
      any,
      { id: string; comment: string }
    >({
      query: ({ comment, id }) => ({
        url: `practices/${id}/abort`,
        method: 'POST',
        body: {
          comment,
        },
      }),
    }),
    preStartApproval: builder.mutation<any, ApprovalPreStart>({
      query: ({ practiceId, ...body }) => ({
        url: `practices/${practiceId}/approval/pre-start`,
        method: 'POST',
        body: {
          length_of_approval: body.lengthOfApproval,
          comment: body.comment,
          due_date: body.dueDate,
          send_pre_notice: body.sendPreNotice,
        },
      }),
    }),
    startApproval: builder.mutation<any, string>({
      query: (approvalId) => ({
        url: `approval-process/${approvalId}/start`,
        method: 'POST',
      }),
    }),
    // @todo rename this, it's actually an update, also in the BE. this is reaaaally confusing naming ._.
    addApprovalAssignees: builder.mutation<
      any,
      { processID: string; stepID: string; userIDs: string[] }
    >({
      query: ({ processID, stepID, userIDs }) => ({
        url: `approval-process/${processID}/add-assignees`,
        method: 'POST',
        body: {
          approval_steps: [
            {
              id: stepID,
              user_ids: userIDs,
            },
          ],
        },
      }),
    }),
    rejectApproval: builder.mutation<any, { stepID: string; comment: string }>({
      query: ({ stepID, comment }) => ({
        url: `approval-step/${stepID}/reject`,
        method: 'POST',
        body: {
          comment,
        },
      }),
    }),
    approveApprovalStep: builder.mutation<any, string>({
      query: (approvalId) => ({
        url: `approval-step/${approvalId}/approve`,
        method: 'POST',
      }),
    }),
    addCollaborators: builder.mutation<
      any,
      { practiceId: string; userIds: string[] }
    >({
      query: ({ practiceId, userIds }) => ({
        url: `practices/${practiceId}/collaborators`,
        method: 'POST',
        body: {
          user_ids: userIds,
        },
      }),
    }),
    deleteCollaborators: builder.mutation<
      any,
      { practiceId: string; collaboratorId: string }
    >({
      query: ({ practiceId, collaboratorId }) => ({
        url: `practices/${practiceId}/collaborators/${collaboratorId}`,
        method: 'DELETE',
      }),
    }),
    uploadDocuments: builder.mutation<
      any,
      {
        practiceId: string
        data: {
          file_id: string
          document: {
            name: string
            type: string
          }
          metadata: Partial<MetadataRequest>[]
        }[]
      }
    >({
      query: ({ practiceId, data }) => ({
        url: `/practices/${practiceId}/upload/metadata`,
        method: 'POST',
        body: {
          documents: data,
        },
      }),
    }),
    setOutdated: builder.mutation<any, { practiceId: string }>({
      query: ({ practiceId }) => ({
        url: `/practices/${practiceId}/set-outdated`,
        method: 'PATCH',
        body: {},
      }),
    }),
  }),
})

export const {
  /*
    ------------------------
    👇 QUERIES 👇
    ------------------------
    */
  useGetAllPracticesQuery,
  useGetPracticeByIdQuery,
  useGetMinimalPracticeByIdQuery,
  useGetLatestRevisionByPracticeQuery,
  useGetPreCheckStatusByPracticeIdQuery,
  useGetLatestApprovalByPracticeIdQuery,
  /*
    ------------------------
    👇 MUTATIONS 👇
    ------------------------
    */
  useUpdateMetadataByPracticeMutation,
  useStartPreCheckByPracticeIdMutation,
  useAddCommentMutation,
  useAbortCheckByPracticeIdMutation,
  usePreStartApprovalMutation,
} = practicesApi
