import {
  OfficeName,
  PracticeQueryParams,
  RevisionStatus,
} from 'constants/application-constants'

import LockIcon from 'assets/Icons/LockIcon'
import { Badge, Breadcrumbs, Button, IconContainer } from 'components/common'
import { useAppSelector } from 'hooks'
import { t } from 'i18next'
import { Folder } from 'react-feather'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import styles from './BackOfficeHeader.module.scss'
import { selectCurrentRevision } from '../../api'
import CollaboratorsButton from '../CollaboratorsButton/CollaboratorsButton'
import MetadataLanguageDropdown from '../MetadataLanguageDropdown/MetadataLanguageDropdown'
import PreCheckStatusInfo from '../PreCheckStatusInfo/PreCheckStatusInfo'
import RevisionSelect from '../RevisionSelect/RevisionSelect'
import UploadDocumentButton from '../UploadDocumentButton/UploadDocumentButton'

const BackOfficeHeader = (): JSX.Element => {
  const currentRevision = useAppSelector(selectCurrentRevision)
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const location = useLocation()

  const currentMetadata = currentRevision?.metadata.find(
    ({ language }) =>
      searchParams.get(PracticeQueryParams.METADATA_LANGUAGE) === language.code
  )
  const revisionStatus = searchParams.get(PracticeQueryParams.REVISION_STATUS)

  const handleViewAsEnduserClick = () => {
    const frontOfficeUrl = `/${OfficeName.FRONT}/practices/${currentRevision?.practice_id}`

    navigate({
      ...location,
      pathname: frontOfficeUrl,
      search: `?${PracticeQueryParams.REVISION_STATUS}=${RevisionStatus.PUBLISHED}`,
    })
  }

  return (
    <div className={styles.root}>
      <nav className={styles.nav}>
        <Breadcrumbs
          crumbs={[
            {
              href: '/practices',
              title: 'My Guidelines',
              icon: <Folder />,
              iconOnly: true,
              tooltip: t('home.backToGuideline'),
            },
            {
              href: location,
              title: currentMetadata?.title ?? t('practice.current'),
              tag: <RevisionSelect />,
            },
          ]}
        />
      </nav>
      <div className={styles.main} />
      <div className={styles.actions}>
        <MetadataLanguageDropdown />
        {/* TODO: "More" Dropdown */}

        {!!revisionStatus &&
          revisionStatus === RevisionStatus.PUBLISHED &&
          currentRevision?.practice_id && (
            <Button
              variant="secondary"
              size="small"
              onClick={handleViewAsEnduserClick}
            >
              {t('practice.viewAsEnduser')}
            </Button>
          )}

        {!!revisionStatus && revisionStatus !== RevisionStatus.DRAFT && (
          <Badge size="small" color="tertiary" className={styles.badge}>
            <IconContainer
              className={styles.icon}
              size="x-small"
              icon={<LockIcon />}
            />
            {t('practice.viewOnly')}
          </Badge>
        )}
        {!!revisionStatus && revisionStatus === RevisionStatus.DRAFT && (
          <>
            <CollaboratorsButton />
            <PreCheckStatusInfo />
            <UploadDocumentButton />
          </>
        )}
      </div>
    </div>
  )
}

export default BackOfficeHeader
