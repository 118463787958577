import { SearchQueryParams } from 'constants/application-constants'

import { StarredSearch } from 'types'

import { buildQueryString } from './url-helpers'

export const createSearchParams = (
  config: Partial<StarredSearch['configuration']>
) =>
  buildQueryString({
    [SearchQueryParams.QUERY]: config.search || '+',
    [SearchQueryParams.LIMIT]: config.limit,
    [SearchQueryParams.RELEVANCE]: config.filter_by_relevance ? '1' : undefined,
    [SearchQueryParams.DEPARTMENT]: config.departments?.map((d) => d.id),
    [SearchQueryParams.LANGUAGE]: config.languages?.map((l) => l.id),
    [SearchQueryParams.LOCATION]: config.locations?.map((l) => l.id),
    [SearchQueryParams.TOPIC]: config.topics?.map((t) => t.id),
    [SearchQueryParams.TYPE]: config.type?.map((t) => encodeURIComponent(t.id)),
    [SearchQueryParams.AUTHOR]: config.author?.map((t) => t.id),
    [SearchQueryParams.CERTIFICATION]: config.certifications?.map((t) => t.id),
    [SearchQueryParams.PRACTICE_CLASS]: config.practice_classes?.map(
      (t) => t.id
    ),
    [SearchQueryParams.LEGAL_ENTITY]: config.legal_entities?.map((t) => t.id),
    [SearchQueryParams.COUNTRY]: config.countries?.map((t) => t.id),
    [SearchQueryParams.COLLECTION]: config.collections?.map((t) => t.id),
  })
