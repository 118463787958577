import { memo } from 'react'

import { InputWrapper, MultiSelect } from 'components/common'
import { FieldHookConfig, useField } from 'formik'
import { t } from 'i18next'

type FMultiSelectProps = React.ComponentProps<typeof MultiSelect> &
  FieldHookConfig<string[] | null | undefined> & {
    helperText?: string
    optional?: boolean
  }
const FMultiSelectComponent = (props: FMultiSelectProps) => {
  const [field, meta, helpers] = useField(props)
  const {
    helperText,
    onSelect: _onSelect,
    onSearchBlur: _onSearchBlur,
    variant: _variant,
    label,
    optional,
    ...rest
  } = props

  const hasError = meta.touched && !!meta.error

  const handleSelect = (allItems: string[], item: string | null) => {
    helpers.setTouched(true)
    helpers.setValue(allItems)

    if (props.onSelect) {
      props.onSelect(allItems, item)
    }
  }

  const handleBlur = () => {
    helpers.setTouched(true)

    if (props.onSearchBlur) {
      props.onSearchBlur()
    }
  }

  return (
    <InputWrapper
      helperText={hasError ? t(meta.error as string) : helperText}
      color={hasError ? 'error' : 'basic'}
      onClick={(e) => e.stopPropagation()}
      onKeyPress={(e) => {
        e.stopPropagation()
        if (e.code === 'Enter') e.preventDefault()
      }}
    >
      <MultiSelect
        label={optional ? `${label} (${t('common.optional')})` : label}
        variant={hasError ? 'error' : 'default'}
        onSearchBlur={handleBlur}
        onSelect={handleSelect}
        defaultValue={field.value ? field.value : undefined}
        {...rest}
      />
    </InputWrapper>
  )
}

const FMultiSelect = memo(FMultiSelectComponent)

export default FMultiSelect
