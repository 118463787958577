import {
  OfficeName,
  Permissions,
  RevisionStatus,
} from 'constants/application-constants'
import LocationValidationState from 'constants/enums/LocationValidationState'

import { lazy, Suspense, useMemo } from 'react'

import { FileCheckIcon } from 'assets/Icons'
import {
  Button,
  Card,
  LoadingIndicator,
  Stepper,
  Typography,
} from 'components/common'
import { DocDetailQuery } from 'components/features/Documents/enums'
import {
  practicesApi,
  selectCurrentRevision,
} from 'components/features/Practices/api'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'
import { useAppSelector, usePermissions, useToggle } from 'hooks'
import { t } from 'i18next'
import { Edit2, Lock, Shield } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import { ApprovalRejectReasons, Revision } from 'redux-store/models'
import { ValidationSchemaLevel } from 'types'

import styles from './ApprovalStartPage.module.scss'
import MissingDoc from './MissingDoc'
import config from '../../../../../config'
import UploadDocumentButton from '../../../Practices/components/UploadDocumentButton/UploadDocumentButton'
import { userApi } from '../../../User/api'
import { selectApproval } from '../../api/approvalSlice'

const ConfigureApprovalModal = lazy(
  () => import('../ConfigureApprovalModal/ConfigureApprovalModal')
)

type ValidationState = {
  validateOnMount: boolean
  validationLevel: ValidationSchemaLevel
}

const validateLocationForm: ValidationState = {
  [LocationValidationState.VALIDATE_ON_MOUNT]: true,
  [LocationValidationState.VALIDATION_LEVEL]: 'strict',
}

const ApprovalStartPage = (): JSX.Element | null => {
  const currentRevision = useAppSelector(selectCurrentRevision)
  const { practiceId } = useAppSelector(selectApproval)
  const [isModalOpen, toggleModal] = useToggle(false)
  const navigate = useNavigate()
  const location = useLocation()

  const httpGetPractice = practicesApi.useGetPracticeByIdQuery(
    practiceId ?? '',
    {
      skip: !practiceId,
    }
  )

  const { isSuccess: currentUserSuccess, data: currentUserData } =
    userApi.endpoints.getCurrentUser.useQueryState(null)

  const preCheckOngoing = useMemo(() => {
    if (!httpGetPractice.isSuccess) return false
    if (!httpGetPractice.data) return false

    return httpGetPractice.data.data.revision_statuses?.includes(
      RevisionStatus.PRECHECK
    )
  }, [httpGetPractice.data, httpGetPractice.isSuccess])

  const { mixed: allPermissions } = usePermissions(practiceId ?? '')

  if (!allPermissions || httpGetPractice.isFetching || !currentRevision) {
    return <LoadingIndicator />
  }

  const handlePreCheckClick = (): void => {
    navigate('pre-check')
  }
  const handleApproveClick = (): void => {
    toggleModal()
  }

  const handlePracticeMetadataClick = () => {
    navigate(
      {
        ...location,
        pathname: 'edit',
      },
      {
        state: validateLocationForm,
      }
    )
  }

  const goToDocReplace = (docId: string) => () => {
    navigate(
      {
        pathname: `/${OfficeName.BACK}/documents/${docId}/edit`,
        search: `?${DocDetailQuery.PRACTICE_ID}=${practiceId}&rs=${RevisionStatus.DRAFT}`,
      },
      {
        state: validateLocationForm,
      }
    )
  }

  const readyForApproval = currentRevision.ready_for_approval?.ok
  const reasons =
    currentRevision.ready_for_approval?.reason || ({} as ApprovalRejectReasons)

  const reasonExists = (reasonKey: string): boolean => {
    if (!reasons) {
      return false
    }

    return reasons.hasOwnProperty(reasonKey)
  }

  const getStepStatus = (
    reasonKey: keyof ApprovalRejectReasons
  ): 'complete' | 'current' => {
    if (reasonExists(reasonKey)) {
      return 'current'
    }

    return 'complete'
  }

  const status = {
    mainDoc: getStepStatus('no_main_document'),
    revisionMetadata: getStepStatus('revision_metadata_missing'),
    docsMetadata: getStepStatus('documents_metadata_missing'),
  }

  const getMissingDocTitle = getDocTitle(currentRevision)

  const formatOptions =
    currentUserSuccess && currentUserData?.data.language === 'de'
      ? { locale: de }
      : {}

  return (
    <div className={styles.page}>
      <Suspense fallback={<div />}>
        <ConfigureApprovalModal
          practiceId={practiceId ?? ''}
          modalOpen={isModalOpen}
          toggleModal={toggleModal}
        />
      </Suspense>
      {allPermissions[Permissions.START_APPROVAL] && (
        <Stepper className={styles.stepper}>
          <Stepper.Step
            stage="1"
            status={status.mainDoc}
            title={t('approvalStart.step1Title')}
            subtitle={t('approvalStart.step1Subtitle')}
          >
            {!readyForApproval && status.mainDoc === 'current' && (
              <UploadDocumentButton />
            )}
          </Stepper.Step>
          <Stepper.Step
            stage="2"
            status={status.revisionMetadata}
            title={t('approvalStart.step2Title')}
            subtitle={t('approvalStart.step2Subtitle')}
          >
            {!readyForApproval && status.revisionMetadata === 'current' && (
              <Button
                size="small"
                icon={<Edit2 />}
                variant="secondary"
                onClick={handlePracticeMetadataClick}
              >
                {t('approvalStart.step2Button')}
              </Button>
            )}
          </Stepper.Step>
          <Stepper.Step
            stage="3"
            status={status.docsMetadata}
            title={t('approvalStart.step3Title')}
            subtitle={t('approvalStart.step3Subtitle')}
          >
            {!readyForApproval && status.docsMetadata === 'current' && (
              <div className={styles.missingDocs}>
                <div className={styles.missingDocsHeader}>
                  <Typography
                    size="small"
                    element="span"
                    fontWeight="medium"
                    className={styles.title}
                  >
                    {t('approvalStart.step3DocumentsHeader')}
                  </Typography>
                  <div className={styles.count}>
                    <Typography
                      size={'small'}
                      element={'span'}
                      fontWeight={'medium'}
                    >
                      {reasons?.documents_metadata_missing?.length}
                    </Typography>
                  </div>
                </div>
                {reasons?.documents_metadata_missing?.map((id: string) => {
                  return (
                    <MissingDoc
                      key={id}
                      title={getMissingDocTitle(id)}
                      onActionClick={goToDocReplace(id)}
                    />
                  )
                })}
              </div>
            )}
          </Stepper.Step>

          <Stepper.Step
            stage={preCheckOngoing ? <Lock /> : '4'}
            status={readyForApproval ? 'current' : 'incomplete'}
            title={t('approvalStart.step4Title')}
            subtitle={t('approvalStart.step4Subtitle')}
          >
            {preCheckOngoing && (
              <Card color="primary" noDropShadow>
                <Card.Body>
                  <Typography size="small">
                    {t('approvalStart.step4Card')}
                  </Typography>
                </Card.Body>
              </Card>
            )}
            {!preCheckOngoing && readyForApproval && (
              <>
                {httpGetPractice?.data?.data?.latest_published_revision && (
                  <Typography
                    size={'small'}
                    fontWeight={'medium'}
                    className={styles.lastApproved}
                  >
                    {t('approvalStart.lastApprovedOn')}{' '}
                    {format(
                      new Date(
                        httpGetPractice?.data?.data?.latest_published_revision.published_at
                      ),
                      'dd MMM, yyyy',
                      formatOptions
                    )}{' '}
                    {t('approvalStarted.at')}{' '}
                    {format(
                      new Date(
                        httpGetPractice?.data?.data?.latest_published_revision.published_at
                      ),
                      'kk:mm'
                    )}
                  </Typography>
                )}
                <Button
                  size="small"
                  variant="secondary"
                  onClick={handleApproveClick}
                  icon={<FileCheckIcon />}
                  className={styles.startButton}
                >
                  {t('approvalStart.step4Button')}
                </Button>
              </>
            )}
          </Stepper.Step>
        </Stepper>
      )}

      {config.IS_DEMO &&
        allPermissions[Permissions.START_PRE_CHECK] &&
        !preCheckOngoing && (
          <Card noDropShadow color="primary">
            <Card.Body className={styles.preCheck}>
              <div className={styles.preCheckTitle}>
                <Shield />
                <Typography element="h2" fontWeight="medium">
                  {t('approvalStart.preCheckTitle')}
                </Typography>
              </div>
              <Typography size="small" color="label">
                {t('approvalStart.preCheckDescription')}
              </Typography>
              <Button
                onClick={handlePreCheckClick}
                variant="secondary"
                size="small"
              >
                {t('approvalStart.preCheckAction')}
              </Button>
            </Card.Body>
          </Card>
        )}
    </div>
  )
}

const getDocTitle =
  (revision: Revision) =>
  (docId: string): string => {
    const currDoc = revision?.documents?.find(
      (document) => document.id === docId
    )

    if (currDoc) {
      return currDoc.metadata.title
    }

    const mainDoc = revision?.main_document?.find(
      (document) => document.id === docId
    )

    if (mainDoc) {
      return mainDoc.metadata.title
    }

    return ''
  }

export default ApprovalStartPage
