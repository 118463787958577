import { OfficeName } from 'constants/application-constants'

import React, { useMemo } from 'react'

import { Avatar, Badge, Table, Typography } from 'components/common'
import { DocumentIcon } from 'components/features/Other/components'
import { formatDateNumeric } from 'helpers'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Practice } from 'redux-store/models'

import styles from './PracticesTable.module.scss'
import { useSortable } from '../../../../../hooks'

interface PracticesTableProps {
  practices: Practice[]
  navigateToBackOffice?: boolean
  labelledBy?: string
}

type PracticesTableSortBy = 'name' | 'author' | 'topic' | 'updatedAt'

/**
 * @todo for refactoring: this component uses /practices/(frontoffice and backoffice) endpoints. Those currently use a
 *      shim function to convert data into the format required here. Once we implement a pagination the shim needs to
 *      be removed, which required changes to this component but will provide URLs for paginated data.
 */
const PracticesTable = ({
  practices,
  navigateToBackOffice,
  labelledBy,
}: PracticesTableProps) => {
  const { sortBy, sortDir, handleSortClick } =
    useSortable<PracticesTableSortBy>({
      sortByDefault: 'updatedAt',
      sortDirDefault: 'desc',
    })
  const { t } = useTranslation()

  const tableData = useMemo(() => {
    const data = practices.map((practice) => ({
      // TODO: instead of metadata[0] use a more predictable metric for metadata language selection (eg. user language)
      name: practice.latest_displayable_revision?.metadata[0]?.title,
      author:
        practice.latest_displayable_revision?.metadata[0]?.contact_user?.name,
      initials:
        practice.latest_displayable_revision?.metadata[0]?.contact_user
          ?.initials,
      avatar_public_path:
        practice.latest_displayable_revision?.metadata[0]?.contact_user
          ?.avatar_public_path,
      topic:
        practice.latest_displayable_revision?.metadata[0]?.main_topic?.name,
      updatedAt:
        practice.latest_displayable_revision?.updated_at ||
        practice.latest_displayable_revision?.published_at ||
        practice.latest_displayable_revision?.created_at,
      linkTarget: practice.id,
      count: practice.latest_displayable_revision?.number_of_documents,
      documents: practice.latest_displayable_revision?.documents ?? [],
    }))

    if (sortBy) {
      return data.sort((a, b) => {
        const _a = a[sortBy]?.toLowerCase() ?? ''
        const _b = b[sortBy]?.toLowerCase() ?? ''
        if (_a < _b) {
          return sortDir === 'asc' ? -1 : 1
        } else if (_a > _b) {
          return sortDir === 'asc' ? 1 : -1
        } else {
          return 0
        }
      })
    }
    return data
  }, [practices, sortBy, sortDir])

  return (
    <>
      <Table isFullWidth aria-labelledby={labelledBy} className={styles.root}>
        <Table.Head>
          <Table.Row isHeadRow>
            <Table.Cell
              onClick={() => handleSortClick('name')}
              sortDir={sortDir}
              sortByThisColumn={sortBy === 'name'}
            >
              {t('practicesTable.head1')}
            </Table.Cell>
            <Table.Cell
              onClick={() => handleSortClick('topic')}
              sortDir={sortDir}
              sortByThisColumn={sortBy === 'topic'}
            >
              {t('practicesTable.head2')}
            </Table.Cell>
            <Table.Cell
              onClick={() => handleSortClick('author')}
              sortDir={sortDir}
              sortByThisColumn={sortBy === 'author'}
            >
              {t('practicesTable.head3')}
            </Table.Cell>
            <Table.Cell
              onClick={() => handleSortClick('updatedAt')}
              className={styles.dateCell}
              sortDir={sortDir}
              sortByThisColumn={sortBy === 'updatedAt'}
            >
              {t('practicesTable.head4')}
            </Table.Cell>
            <Table.Cell aria-label={t('practicesTable.head5Aria') ?? ''} />
          </Table.Row>
        </Table.Head>
        <Table.Body data-testid="practice-table-tbody">
          {tableData.map((item) => (
            <Table.Row key={item.linkTarget} isClickable>
              <Table.Cell isLead>
                <Link
                  to={{
                    pathname: `/${
                      navigateToBackOffice ? OfficeName.BACK : OfficeName.FRONT
                    }/practices/${item.linkTarget}`,
                  }}
                  aria-label={item.name + ', go to detailpage'}
                />
                <DocumentIcon type="practice" />

                <div className="is-only-touch">
                  <div className={styles.content}>
                    <Typography className={styles.nameText}>
                      {item.name}
                    </Typography>
                    <Typography size="small" color="primary">
                      {item.topic}
                    </Typography>
                  </div>
                </div>

                <div className="is-not-touch">
                  <Typography className={styles.nameText}>
                    {item.name}
                  </Typography>
                </div>
              </Table.Cell>
              <Table.Cell>{item.topic}</Table.Cell>
              <Table.Cell>
                <div className={styles.contentAuthor}>
                  <Avatar
                    content={item.initials}
                    size="small"
                    src={item.avatar_public_path}
                  />
                  {item.author}
                </div>
              </Table.Cell>
              <Table.Cell className={styles.dateCell}>
                {item.updatedAt ? formatDateNumeric(item.updatedAt) : ''}
              </Table.Cell>
              <Table.Cell>
                {Boolean(item.count) ? (
                  <Badge>{item.count}</Badge>
                ) : (
                  <Badge>1</Badge>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
}

export default PracticesTable
